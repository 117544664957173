import React from "react";
import { useGlobalContext } from "../../Contexts/GlobalContext";
import useWindowDimensions from "../../Hooks/UseWindowDimensions";
import landingPagePointsImg from "../../Resources/Images/landingPagePoints.svg";
import studentsRegisteredImg from "../../Resources/Images/studentsRegistered.svg";
import { isNULLEMPTYORUNDEFINED } from "../../Utilities/CommonUtility/CommonUtility";
import EventLeads from "../Events/EventLeads";
import { companiesImages } from "./IntroConstants";
import "./styles/Intro.css";

const Intro = (props) => {
    const { windowWidth } = useWindowDimensions();
    const event_details = props.event_details;
    const eventFeatures = props?.event_details?.event_features;
    const { setRegisterFormModalOpen, setLeadsFormModalOpen } = useGlobalContext();

    const getIntroHeadingAndCaption = () => {
        return (
            <>
                <h1 className="intro-heading">{event_details.event_title}</h1>
                <h3 className="intro-caption-subheading">{event_details.description}</h3>
            </>
        );
    };

    const getIntroWhatWeOfferList = () => {
        return (
            <>
                {eventFeatures &&
                    eventFeatures.map((data, index) => (
                        <div key={index} className="intro-list">
                            <div className="intro-images-point">
                                <img alt="" src={landingPagePointsImg}></img>
                            </div>
                            <div>
                                <p className="intro-list-para">{data}</p>
                            </div>
                        </div>
                    ))}
            </>
        );
    };

    const openLeadsFormModal = () => {
        setLeadsFormModalOpen(true);
    };

    const getButtons = () => {
        return (
            <div className="intro-buttons">
                <button
                    className="intro-register-button"
                    onClick={() => setRegisterFormModalOpen(true)}
                >
                    Register Now
                </button>
                {!isNULLEMPTYORUNDEFINED(props.event_details.event_curriculum_pdf) && (
                    <>
                        <button
                            className="intro-curriculum-button"
                            onClick={() => openLeadsFormModal()}
                        >
                            Download Curriculum
                        </button>
                        <EventLeads event_details={props.event_details} />
                    </>
                )}
            </div>
        );
    };

    const getStudentRegistered = () => {
        return (
            <>
                <div className="student-registered-wrapper">
                    <img alt="" src={studentsRegisteredImg} />
                    <p className="student-registered">
                        {event_details.total_participants}+ Students Already Registered
                    </p>
                </div>
            </>
        );
    };

    return (
        <div className="introduction-wrapper">
            <div className="intro-contents-wrapper">
                {getIntroHeadingAndCaption()}
                {!isNULLEMPTYORUNDEFINED(event_details.intro_video_link) && windowWidth < 600 ? (
                    <div className="event-intro-video-player-mobile">
                        <iframe
                            src={event_details.intro_video_link}
                            className="video-player"
                            allow="autoplay"
                        ></iframe>
                    </div>
                ) : !isNULLEMPTYORUNDEFINED(event_details.event_image) && windowWidth < 600 ? (
                    <div className="event-intro-banner-image-wrapper">
                        <img src={event_details.event_image} alt="" />
                    </div>
                ) : (
                    <></>
                )}
                <div className="intro-list-wrapper">{getIntroWhatWeOfferList()}</div>
                <div className="time-and-requirement-wrapper">{getStudentRegistered()}</div>
                {getButtons()}

                <p className="free-trail">
                    {isNULLEMPTYORUNDEFINED(event_details.recommended_for) ? (
                        <p>Limited Seats | First come first serve</p>
                    ) : (
                        <p>{event_details.recommended_for}</p>
                    )}
                </p>
                {event_details.event_type === "paid" && (
                    <p className="referral-cashback">
                    Refer a friend and get <span className="cashback-amount">₹1000 cashback!</span>
                    </p>
                )}


                {event_details.event_companies && (
                    <div className="companies-logo-and-topic">
                        <p>{event_details.event_companies.topic}</p>
                        <div className="event-company-images">
                            {event_details.event_companies.companies.map((company) => {
                                let companylogo = companiesImages[company];
                                return (
                                    <img
                                        className={`company-logo-${company}`}
                                        src={companylogo}
                                        alt=""
                                    />
                                );
                            })}
                        </div>
                    </div>
                )}
            </div>

            {!isNULLEMPTYORUNDEFINED(event_details.intro_video_link) && windowWidth > 600 ? (
                <div className="event-intro-video-player">
                    <iframe
                        src={event_details.intro_video_link}
                        className="video-player"
                        allow="autoplay"
                    ></iframe>
                </div>
            ) : !isNULLEMPTYORUNDEFINED(event_details.event_image) && windowWidth > 600 ? (
                <div className="event-intro-banner-image-wrapper">
                    <img src={event_details.event_image} alt="" />
                </div>
            ) : (
                <></>
            )}
        </div>
    );
};

export default Intro;
